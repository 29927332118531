import { stringifyParsedURL, stringifyQuery } from "ufo"

export const MakeURL = {
  loginFirst(url?: string) {
    if (url) {
      return `/auth/login?redirect=${encodeURIComponent(url)}`
    } else {
      return `/auth/login`
    }
  },
  join() {
    return "/join"
  },
  resetPassword(redirect?: string) {
    return this.fromPath("/auth/login", {
      redirect,
      mode: "forgot",
    })
  },
  fromPath(pathname: string, query?: Record<string, any>, hash?: string) {
    return stringifyParsedURL({ pathname, search: query ? stringifyQuery(query) : undefined, hash })
  },
  fromRoute(route: { pathname: string; query: Record<string, any> }) {
    const qs = new URLSearchParams(route.query).toString()
    return route.pathname + (qs ? "?" + qs : "")
  },
  discover(querystring: Partial<Record<"branch" | "collection" | "category", any>>) {
    return this.fromPath("/discover", querystring)
  },
  account(page: "account" | "my-info" | "membership" | "profile" | "billing" | "change-password" | "prefs" | "team", query: Record<string, any> = {}) {
    return this.fromPath("/account/" + page, query)
  },
  renew(planSlug?: string) {
    return this.fromPath("/join", { renew: true, plan: planSlug })
  },
  contact(reason?: string) {
    return this.fromPath("/contact", { reason })
  },
  findDesigner(query?: { expertise?: string; location?: string; type?: "student" | "professional" }) {
    return this.fromPath("/directory", query)
  },
  awardSearch(query?: { year?: string | number; category?: string; level?: string[] }) {
    return this.fromPath("/awards", query)
  },
}
